<template>
  <v-app>
    <!-- 
        class="p-0 m-0"
    style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
   -->
    <Timer class="mb-3" />
    <MedicalFile class="mx-4 mt-2"></MedicalFile>
  </v-app>
</template>

<script>
import ApiService from "@/core/services/api.service";
import MedicalFile from "@/view/pages/medical_file/file.vue";
import Timer from "./Timer";

export default {
  name: "clinicapp",

  components: {
    MedicalFile,
    Timer
  },
  data() {
    return {
      loading: false,
      d: 0
    };
  },
  watch: {
    duration(updated) {
      if (updated) {
        this.d = updated;
      }
    }
  },
  mounted() {
    this.getVia();
  },
  methods: {
    isDoctor() {
      if (JSON.parse(localStorage.userInfo).role == 3) return true;
      else return false;
    },
    async getAppointment() {
      //var userInfo = JSON.parse(localStorage.userInfo).id;
      ApiService.get(
        "items",
        "appointments/" +
          this.$route.params.id +
          "?fields=id,status,video_room_id,rating,type,date,time,date_time,medical_file.owner.id," +
          "doctor.doctor_id.first_name,doctor.doctor_id.last_name,doctor.doctor_id.avatar.data,doctor.doctor_id.id," +
          "medical_file.owner.first_name,medical_file.owner.last_name,medical_file.owner.avatar.data,medical_file.id,medical_file.birthday,medical_file.gender,rating"
      )
        .then(({ data }) => {
          //// console.log(data.data);
          this.app = data.data;
        })
        .catch(error => {
          this.error = error;
        });
    },

    async getVia() {
      //var userInfo = JSON.parse(localStorage.userInfo).id;
      // console.log("checking via");
      await ApiService.get(
        "items",
        "md_doctor_actions" + "?filter[appointment]=" + this.$route.params.id
      )
        .then(({ data }) => {
          // console.log(data.data);
          if (data.data.length > 0) {
            // console.log("found via object and it's id is: ", data.data[0].id);
            this.$root.viaid = data.data[0].id;
          } else {
            // console.log("didn't find via and should create one");
            this.setVia();
          }
        })
        .catch(error => {
          this.error = error;
        });
    },
    async setVia() {
      await ApiService.post("items/md_doctor_actions", {
        type: 1,
        appointment: this.$route.params.id,
        medical_file: this.$root.doctormf
      }).then(({ data }) => {
        // console.log(data.data);
        // console.log("created via");
        this.$root.viaid = data.data.id;
      });
    },
    close() {
      this.$emit("closeClicked", false);
    }
  }
};
</script>
