<template>
  <v-app
    class="p-0 m-0"
    style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
  >
    <v-main class="bg-dark">
      <div
        v-if="!expired"
        style="position:absolute;text-align: center; width:100%; margin-top:1vh; color:white; font-weight:bold"
      >
        {{ mintues }}: {{ seconds }}
      </div>
      <!--begin::Dashboard-->
      <!--begin::Row-->
      <div class="roomTitle text-white">
        <div
          style="position: absolute; width: 100%; top: 25rem"
          class="text-center"
          v-if="loading"
        >
          <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
        </div>
        <div
          style="position: absolute; width: 100%; top: 25rem"
          class="text-center"
          v-else-if="showMessage"
        >
          {{ msg }}
        </div>
      </div>
      <div :style="'height: 100%;'" class="remote_video_container">
        <div
          v-if="$vuetify.breakpoint.mobile"
          style="height: 100%; width: 100%"
          id="remoteTrack"
          ref="remotevid"
        ></div>
        <div v-else style="height: 100%" id="remoteTrack" ref="remotevid"></div>
      </div>

      <div
        class="rounded-lg"
        style="width: 30%; position: absolute; bottom: 9rem; right: 15px;height: max-content;"
        id="localTrack"
        v-if="$vuetify.breakpoint.mobile"
      >
        <div
          style="position: inherit; width: 100%; height:100%;align-content: center;align-items: center;justify-content: center"
          class="text-white"
        >
          <v-avatar
            v-if="mutev"
            color="white"
            size="5rem"
            style="margin-right: auto; margin-left: auto; display: flex"
          >
            <img
              v-if="local_user.avatar != null"
              :src="local_user.avatar.data.thumbnails[0].url"
            />
            <span
              v-else
              style="text-transform: uppercase"
              class="font-size-h3 primary--text"
              >{{
                local_user.first_name.charAt(0) +
                  "" +
                  local_user.last_name.charAt(0)
              }}</span
            ></v-avatar
          >
        </div>
      </div>
      <div
        class="rounded-lg"
        style="width: 20%; position: absolute; bottom: 15px; right: 15px;height: max-content;"
        id="localTrack"
        v-else
      >
        <div
          style="position: inherit;width: 100%; height:100%;display: flex;align-content: center;align-items: center;justify-content: center"
          class="text-white"
        >
          <v-avatar
            v-if="mutev"
            color="white"
            size="50"
            style="margin-right: auto; margin-left: auto; display: flex"
          >
            <img
              v-if="local_user.avatar != null"
              :src="local_user.avatar.data.thumbnails[0].url"
            />
            <span
              v-else
              style="text-transform: uppercase"
              class="font-size-h3 primary--text"
              >{{
                local_user.first_name.charAt(0) +
                  "" +
                  local_user.last_name.charAt(0)
              }}</span
            ></v-avatar
          >
        </div>
      </div>

      <!--end::Row-->
      <!--end::Dashboard-->

      <div
        v-if="!expired"
        class="row p-0 m-0"
        style="position: fixed; bottom: 0px; width: 100%"
      >
        <div
          class="col-lg-4 col-sm-12 bg-success-o-50 py-5 rounded-lg rounded-bottom-0"
          style="margin-right: auto; margin-left: auto"
        >
          <div
            style="width: max-content; margin-right: auto; margin-left: auto"
          >
            <v-btn class="mx-2 " fab small color="gray" @click="muteAudio()">
              <v-icon v-if="!mutea">mdi-microphone</v-icon>
              <v-icon v-else>mdi-microphone-off</v-icon>
            </v-btn>
            <v-btn class="mx-2 " small fab color="gray" @click="muteVideo()">
              <v-icon v-if="!mutev">mdi-video</v-icon>
              <v-icon v-else>mdi-video-off</v-icon>
            </v-btn>
            <v-btn
              fab
              color="red"
              class="white--text mx-2 "
              @click="leaveRoomIfJoined()"
            >
              <v-icon>mdi-phone-hangup</v-icon>
            </v-btn>
            <v-btn small class="mx-2" fab color="gray" @click="showmf = true">
              <v-icon>flaticon2-medical-records-1</v-icon>
            </v-btn>
            <v-btn
              small
              class="mx-2"
              fab
              color="gray"
              @click="chatdialog = true"
            >
              <v-icon>mdi-chat</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-main>
    <MF v-if="!expired" :dialog="showmf" @closeClicked="showmf = false"></MF>

    <!-- web view -->
    <template v-if="!$vuetify.breakpoint.mobile">
      <v-navigation-drawer
        v-model="chatdialog"
        fixed
        right
        width="500px"
        temporary
        style="z-index:10000"
        v-if="room && remote_user"
      >
        <Chat :chatroom="room" :remoteuser="remote_user" />
      </v-navigation-drawer>
    </template>

    <!-- mobile view -->
    <template v-else>
      <v-bottom-sheet
        v-if="room && remote_user"
        v-model="chatdialog"
        fullscreen
        transition="dialog-bottom-transition"
        persistent
        style="padding-top:45px"
        hide-overlay
      >
        <Chat
          @closechat="chatdialog = false"
          :chatroom="room"
          :remoteuser="remote_user"
        />
      </v-bottom-sheet>
    </template>
  </v-app>
</template>

<script>
import ApiService from "@/core/services/api.service";
import MF from "./mf.vue";
import Twilio, { createLocalTracks } from "twilio-video";
import Chat from "@/view/Chat/chat.vue";

export default {
  name: "videoapp",
  props: [
    "room",
    "roomid",
    "local_user",
    "remote_user",
    "date_time",
    "duration"
  ],
  components: { MF, Chat },
  data() {
    return {
      loading: false,
      data: {},
      showmf: false,
      localTrack: false,
      AllLocalTracks: false,
      remoteTrack: "",
      previewTracks: "",
      token: "",
      log: "",
      activeroom: "",
      showMessage: false,
      msg: "",
      mutea: false,
      mutev: false,
      chatdialog: false,
      mintues: 0,
      seconds: "00",
      expired: false
    };
  },
  beforeDestroy() {
    if (this.$root.viaid !== 0 && this.$root.doctormf !== 0)
      this.leaveRoomIfJoined();
  },
  mounted() {
    //this.getAppointment();
    this.$app.getlog(this.roomid);
    console.log("app id", this.roomid);
    var timezone = this.$util.getTimezone();
    var callduration = this.duration;
    this.mintues = callduration;
    this.$moment.locale("en");

    /*// console.log("started time",this.$moment(this.date_time + "Z")
        .add(callduration, "m")
        .tz(timezone)
        .format("x"))*/
    if (
      this.$moment(this.date_time + "Z")
        .add(callduration, "m")
        .tz(timezone)
        .format("x") -
        this.$moment()
          .utc()
          .format("x") >
      0
    ) {
      this.createChat();
      this.countdown(
        this.$moment(this.date_time + "Z")
          .add(callduration, "m")
          .tz(timezone)
          .format("x")
      );
    } else {
      this.expired = true;
      this.msg = "this appointment expired, please book a new one";
      this.showMessage = true;
    }
    this.$moment.locale(localStorage.language);
  },

  methods: {
    countdown(time) {
      var countDownDate = time;
      // console.log("count down start", time);
      // Update the count down every 1 second
      var x = setInterval(() => {
        // Get today's date and time
        var now = new Date().getTime();
        //// console.log("time now", now);

        // Find the distance between now and the count down date
        var distance = countDownDate - now;
        if (
          Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) <=
          parseInt(this.duration)
        ) {
          // Time calculations for days, hours, minutes and seconds
          if (Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) < 10) {
            this.mintues =
              "0" + Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          } else {
            this.mintues = Math.floor(
              (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
          }
          if (Math.floor((distance % (1000 * 60)) / 1000) < 10) {
            this.seconds = "0" + Math.floor((distance % (1000 * 60)) / 1000);
          } else {
            this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
          }
        }

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);
          this.leaveRoomIfJoined(); //document.getElementById("demo").innerHTML = "EXPIRED";
        }
      }, 1000);
    },
    isDoctor() {
      if (JSON.parse(localStorage.userInfo).role == 3) return true;
      else return false;
    },
    async getAppointment() {
      //var userInfo = JSON.parse(localStorage.userInfo).id;
      await ApiService.get(
        "items",
        "appointments/" +
          this.$route.params.id +
          "?fields=id,status,video_room_id,rating,type,date,time,date_time,medical_file.owner.id," +
          "doctor.doctor_id.first_name,doctor.doctor_id.last_name,doctor.doctor_id.avatar.data,doctor.doctor_id.id," +
          "medical_file.owner.first_name,medical_file.owner.last_name,medical_file.owner.avatar.data,medical_file.id,medical_file.birthday,medical_file.gender,rating"
      )
        .then(({ data }) => {
          //// console.log(data.data);
          this.app = data.data;
        })
        .catch(error => {
          this.error = error;
        });
    },

    // Trigger log events
    // dispatchLog(message) {},

    // Attach the Tracks to the DOM.
    attachTracks(tracks, container) {
      tracks.forEach(function(track) {
        container.appendChild(track.attach());
        // console.log("hight change ", container);

        //}
      });
    },

    // Attach the Participant's Tracks to the DOM.
    attachParticipantTracks(participant, container) {
      // console.log("attaching", participant, "to", container);
      let tracks = Array.from(participant.tracks.values());
      this.attachTracks(tracks, container);
    },

    // Detach the Tracks from the DOM.
    detachTracks(tracks) {
      tracks.forEach(track => {
        track.detach().forEach(detachedElement => {
          detachedElement.remove();
        });
      });
    },

    // Detach the Participant's Tracks from the DOM.
    detachParticipantTracks(participant) {
      let tracks = Array.from(participant.tracks.values());
      this.detachTracks(tracks);
    },

    // Leave Room.
    leaveRoomIfJoined() {
      // console.log("activeroom is:", this.activeroom);
      if (this.activeroom) {
        this.AllLocalTracks.forEach(track => {
          track.stop();
          track.disable();
          track.detach();
        });
        //this.localTrack.stop();
        //this.localTrack.disable();
        //this.activeroom.LocalParticipant.unpublish();
        //this.localTrack.detach();
        this.activeroom.disconnect();
        this.$app.logApp(this.roomid, "left", this.$user.getCurrentUser());
        this.$root.viaid = 0;
        this.$root.doctormf = 0;
        this.$router.go(-1);
      }
    },
    muteAudio() {
      if (!this.mutea) {
        this.activeroom.localParticipant.audioTracks.forEach(function(track) {
          track.track.disable();
        });
        this.mutea = true;
      } else {
        this.activeroom.localParticipant.audioTracks.forEach(function(track) {
          track.track.enable();
        });
        this.mutea = false;
      }
    },
    muteVideo() {
      if (!this.mutev) {
        this.activeroom.localParticipant.videoTracks.forEach(function(track) {
          track.track.disable();
        });
        this.localTrack.disable();
        this.mutev = true;
      } else {
        this.activeroom.localParticipant.videoTracks.forEach(function(track) {
          track.track.enable();
        });
        this.localTrack.enable();
        this.mutev = false;
      }
    },

    // Create a new chat
    async createChat() {
      this.loading = true;
      const VueThis = this;
      await ApiService.get(
        "custom",
        "video/getToken?name=" +
          VueThis.local_user.first_name +
          " " +
          VueThis.local_user.last_name +
          "&roomID=" +
          VueThis.room
      )
        .then(({ data }) => {
          //// console.log("toekn", data.data.token);
          VueThis.token = data.data.token;
        })
        .catch(error => {
          VueThis.error = error;
        });
      /*let connectOptions = {
        name: this.VueThis,
          audio: true,
          video: { width: 720, height: 480, frameRate: 24 },
      };*/
      this.leaveRoomIfJoined();

      document.getElementById("remoteTrack").innerHTML = "";
      //VueThis.log +=("passing this token:", VueThis.token) + "<br/>"
      // if local preview is not active, create it
      if (!VueThis.localTrack) {
        createLocalTracks({
          audio: true,
          video: { width: 720, height: 480, frameRate: 24 }
        }).then(localtracks => {
          let localMediaContainer = document.getElementById("localTrack");
          localMediaContainer.appendChild(localtracks[1].attach());

          VueThis.log += "attached local track successfully" + "<br/>";
          VueThis.AllLocalTracks = localtracks;
          VueThis.localTrack = localtracks[1];
          return Twilio.connect(this.token, {
            name: this.VueThis,
            tracks: localtracks
          }).then(function(troom) {
            VueThis.$app.logApp(
              VueThis.roomid,
              "joined",
              VueThis.$user.getCurrentUser()
            );
            // // console.log('Successfully joined a Room: ', room);
            VueThis.log +=
              "Successfully joined a Room: " + VueThis.room + "<br/>";
            VueThis.activeroom = troom;
            VueThis.loading = false;
            VueThis.msg = "Connected, Waiting for other party ..";
            VueThis.showMessage = true;

            // Attach the Tracks of all the remote Participants.
            troom.participants.forEach(function(participant) {
              //let previewContainer = document.getElementById("remoteTrack");
              console.log("particepent ", participant);
              //VueThis.attachParticipantTracks(participant, previewContainer);
            });
            // When a Participant joins the Room, log the event.
            troom.on("participantConnected", function(participant) {
              VueThis.log +=
                "Joining: '" + participant.identity + "'" + "<br/>";
            });
            // When a Participant adds a Track, attach it to the DOM.
            troom.on("trackSubscribed", function(track, participant) {
              VueThis.log +=
                participant.identity + " added track: " + track.kind + "<br/>";
              let previewContainer = document.getElementById("remoteTrack");
              VueThis.attachTracks([track], previewContainer);
              VueThis.showMessage = false;
            });

            // When a Participant removes a Track, detach it from the DOM.
            troom.on("trackUnsubscribed", function(track, participant) {
              VueThis.log +=
                participant.identity +
                " removed track: " +
                track.kind +
                "<br/>";
              VueThis.detachTracks([track]);
            });

            // When a Participant leaves the Room, detach its Tracks.
            troom.on("participantDisconnected", function(participant) {
              VueThis.log +=
                "Participant '" +
                participant.identity +
                "' left the room" +
                "<br/>";
              VueThis.detachParticipantTracks(participant);
            });
          });
        });
      }
    }
  }
};
</script>
<style>
#localTrack > video {
  width: 100% !important;
  margin-bottom: -5px;
  border-radius: 8px;
}
#remoteTrack > video {
  height: 100% !important;
  margin-right: auto;
  margin-left: auto;
  width: inherit;
  display: inherit;
}
</style>
